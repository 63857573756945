.sign-in-title {
    font-weight: 500;
    font-size: 24px;
}

.page-container {
    height: 100vh;
    width: 100%;
    background-image: url('../../../public/AppBackground.png');
    background-size: cover;
    background-position: center;
}