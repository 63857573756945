.boldLabel{
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
}

.filterBox{
    border: 1px solid rgba(0, 0, 0, 0.27);
    border-radius: 4px;
    width: 100%;
    height: 49px;
    box-shadow: none !important;
}

.subKpiName{
    position: absolute;
    margin-top: -28px !important;
    background-color:white;
}

