.sidebar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 100px);
    width: 220px;
  }

  .sidebar-footer {
    align-self: flex-start;
    margin-bottom: 30px;
  }

  .selected {
    background-color: #FFFFFF;
    border-radius: 8px;
    max-width: 90%;
    color: #000000;
  }